import { ReactNode } from "react";
import { useLocation, Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useWallet } from "../hooks/useWallet";
export default function Footer() {
  const { account, user, addtoken, signin, signout } = useWallet();

  useEffect(() => {
    console.log(user);
  }, [user]);

  const whatYear = () => {
    const d = new Date();
    let year = d.getFullYear();
    return year;
  };
  return (
    <>
      <footer className="footer bg-darker">
        <div className="mt-10 bg-dark">
          <div className="container py-5">
            <div className="row py-lg-4">
              <div className="col-lg-2 mb-lg-0 mb-4">
                {/* 
                <div>
                  <a
                    className="btn-social bs-light bs-twitter me-2 mb-2"
                    href="https://twitter.com/Redlinecrypto"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="ci-twitter"></i>
                  </a>
                  <a
                    className="btn-social bs-light bs-facebook me-2 mb-2"
                    href="https://www.facebook.com/redlineblockchain"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="ci-facebook"></i>
                  </a>
                  <a
                    className="btn-social bs-light bs-instagram me-2 mb-2"
                    href="https://instagram.com/redlineblockchain"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="ci-instagram"></i>
                  </a>
                  <a
                    className="btn-social bs-light bs-youtube me-2 mb-2"
                    href="https://www.youtube.com/channel/UCO3EuajqmgSy2bMNduFcW3Q"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="ci-youtube"></i>
                  </a>
                </div>
                */}
              </div>
              <div className="col-lg-2">
                <div className="d-flex flex-sm-row flex-column justify-content-sm-between mt-n4 mx-lg-n3">
                  <div className="widget widget-links widget-light mt-4 px-lg-3 px-sm-n2">
                    <Link to={"/about"}>
                      <p className=" text-light">About</p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="d-flex flex-sm-row flex-column justify-content-sm-between mt-n4 mx-lg-n3">
                  <div className="widget widget-links widget-light mt-4 px-lg-3 px-sm-n2">
                    <Link to={"/terms-and-conditions"}>
                      <p className=" text-light">Terms and Conditions</p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="d-flex flex-sm-row flex-column justify-content-sm-between mt-n4 mx-lg-n3">
                  <div className="widget widget-links widget-light mt-4 px-lg-3 px-sm-n2">
                    <Link to={"/policy-and-privacy"}>
                      <p className=" text-light">Privacy Policy</p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="d-flex flex-sm-row flex-column justify-content-sm-between mt-n4 mx-lg-n3">
                  <div className="widget widget-links widget-light mt-4 px-lg-3 px-sm-n2">
                    <Link to={"/support"}>
                      <p className="text-light">Suppor - Contact US</p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="d-flex flex-sm-row flex-column justify-content-sm-between mt-n4 mx-lg-n3">
                  <div className="widget widget-links widget-light mt-4 px-lg-3 px-sm-n2">
                    <Link to={"/faq"}>
                      <p className=" text-light">FAQ</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container my-lg-4 my-3 py-2">
          <div className="d-flex flex-md-row flex-column-reverse align-items-center justify-content-md-between">
            <div className="fs-xs text-light opacity-50">
              REDNFT Marketplace. © {whatYear()} Made with{" "}
              <div className="heart">❤</div> for future REDLINERS
            </div>
            <div className="d-flex align-items-start mb-md-0 mb-3 mx-n1">
              <div className="px-1">
                <a
                  className="btn-market btn-apple bg-dark"
                  href="https://apps.apple.com/dk/app/red-wallet/id1607470682"
                >
                  <span className="btn-market-subtitle">Download on the</span>
                  <span className="btn-market-title">App Store</span>
                </a>
              </div>
              <div className="px-1">
                <a
                  className="btn-market btn-google bg-dark"
                  href="https://play.google.com/store/apps/details?id=com.redlineblockchain.wallet&hl=en_US&gl=US"
                >
                  <span className="btn-market-subtitle">Download on the</span>
                  <span className="btn-market-title">Google Play</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="handheld-toolbar">
        <div className="d-table table-layout-fixed w-100">
          <Link className="d-table-cell handheld-toolbar-item" to={"/explorer"}>
            <span className="handheld-toolbar-icon">
              <i className="ci-filter-alt" />
            </span>
            <span className="handheld-toolbar-label explore-1">
              Explore NFTs
            </span>
          </Link>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
export default function About() {
    const [activeSlide] = useState(true);
    return (
        <>
            <div className="pt-4">
                <section className="container">
                    <div className="card py-5 border-0 shadow text-left">
                        <div className="card-body py-md-4 py-3 px-4 text-left ">
                            <p>
                                <b>About Us</b><br />
                                <b>Empowering Creators and Brands in Web3</b>
                            </p>
                            <p >At REDNFT, we are redefining how creators and brands connect with their audiences in the Web3 era. What started as a pioneering NFT marketplace has now evolved into a cutting-edge platform where subscriptions meet the blockchain, enabling sustainable growth and deeper engagement for creators and brands worldwide.
                            </p>
                            <p ><strong>Our Mission</strong></p>
                            <p>To empower creators and brands with innovative tools that unlock the potential of Web3. By blending customizable subscription models with NFT technology, we’re fostering meaningful connections, enabling creativity, and redefining the value of digital ownership.</p>

                            <p><strong>What We Offer</strong></p>
                            <ul >
                                <li ><strong>1.	Subscription-Based Monetization:{" "}</strong>
                                    We provide creators and brands with the ability to build tiered subscription plans, offering exclusive access to their content, events, and perks.
                                </li>
                                <li ><strong>2.	NFT Integration Made Easy:</strong>{" "}
                                    Use NFTs to reward subscribers, grant access to exclusive experiences, or deliver digital collectibles that amplify engagement.
                                </li>
                                <li ><strong>3.	A Global Community:</strong>{" "}
                                    REDNFT connects creators, brands, and fans in a decentralized, vibrant ecosystem where creativity and innovation thrive.
                                </li>
                            </ul>
                            <p >&nbsp;</p>
                            <p><strong>For Creators</strong></p>
                            <p >Whether you’re an artist, writer, musician, or educator, REDNFT is your gateway to sharing your work, building a loyal community, and monetizing your passion.</p>
                            <p><strong>For Brands</strong></p>
                            <p >Harness the power of Web3 to engage your audience with branded subscription models, NFT loyalty programs, and exclusive collaborations.</p>
                            <p><strong>For Fans</strong></p>
                            <p >Support your favorite creators and brands while enjoying exclusive perks, content, and collectibles that you truly own.</p>

                            <p><strong>Why Choose REDNFT?</strong></p>
                            <ul >
                                <li >
                                    <strong>Customizable Solutions:</strong>
                                    Tailored tools for creators and brands to meet their unique needs.
                                </li>
                                <li ><strong>Seamless Integration: </strong>
                                    Intuitive features that bridge Web2 and Web3 effortlessly.
                                </li>
                                <li ><strong>Secure and Transparent: </strong>
                                    Built on blockchain technology to ensure trust and authenticity.
                                </li>
                            </ul>
                            <p><strong>Our Vision</strong></p>
                            <p >We envision a decentralized future where creators and brands have the tools to thrive, audiences are more connected than ever, and digital ownership is meaningful and empowering.</p>
                            <p>Join us on this journey to shape the future of content, commerce, and community in Web3.</p>
                            <strong>Start your journey today with REDNFT.</strong>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
